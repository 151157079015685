import React, { useState, useEffect } from 'react'
import ResourceCard from './resourceCard.js'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBRow, MDBCol, MDBTabContent, MDBTabPane, MDBNav, MDBNavItem } from 'mdbreact'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children
const colHeight = { maxHeight: '14.5rem', minHeight: '9rem' };

const ResourceCarousel = props => {
    // Detect categories from the data structure
    const getCategories = () => {
        // If using categoryList array structure
        if (Array.isArray(props.data.categoryList)) {
            return props.data.categoryList.map(category => ({
                id: category.id,
                name: category.displayName || category.id.charAt(0).toUpperCase() + category.id.slice(1).replace(/-/g, ' '),
                viewAllLink: category.viewAllLink,
                viewAllText: category.viewAllText,
                items: category.items || []
            }));
        }

        // Fallback: If categoryList is missing, return empty array or default placeholder
        return [];
    };

    // Get all available categories
    const availableCategories = getCategories();

    // Set initial state to the first category ID, or 'default' if no categories exist
    const initialCategory = availableCategories.length > 0 ? availableCategories[0].id : 'default';
    const [activeCategory, setActiveCategory] = useState(initialCategory);

    // Update active category if the category list changes
    useEffect(() => {
        // Make sure the active category is still in the list; if not, select the first one
        if (!availableCategories.some(cat => cat.id === activeCategory)) {
            setActiveCategory(availableCategories.length > 0 ? availableCategories[0].id : 'default');
        }
    }, [props.data, availableCategories, activeCategory]);

    // Function to render carousel for specific category
    const renderCategoryCarousel = (items, categoryId) => {
        if (!items || items.length === 0) {
            return <p className="text-center">No items in this category</p>;
        }

        // Desktop version (visible only on large screens)
        const desktopCarousel = (
            <div className="d-none d-lg-block">
                <MDBCarousel
                    activeItem={1}
                    length={Math.ceil(items.length / 3)}
                    slide={true}
                    interval={20000}
                    showControls={false}
                    showIndicators={items.length > 3} // Only show indicators if there are more than 3 items
                    multiItem
                    key={`carousel-desktop-${categoryId}-${carouselKey}`}
                    className="mb-lg-0 mt-lg-5"
                >
                    <MDBCarouselInner>
                        {items.map((_, index) => (
                            <ConditionalWrapper
                                key={index}
                                condition={index % 3 === 0}
                                wrapper={children => (<MDBCarouselItem itemId={index === 0 ? 1 : index % 3 === 0 ? index / 3 + 1 : 0}>{children}</MDBCarouselItem>)}
                            >
                                {index % 3 === 0 && (
                                    <MDBRow className="mx-0">
                                        <MDBCol lg="4" id={index} className="pb-card d-flex">
                                            <ResourceCard item={items[index]} colHeight={colHeight} />
                                        </MDBCol>

                                        {index + 1 < items.length && (
                                            <MDBCol lg="4" id={index + 1} className="pb-card d-flex">
                                                <ResourceCard item={items[index + 1]} colHeight={colHeight} />
                                            </MDBCol>
                                        )}

                                        {index + 2 < items.length && (
                                            <MDBCol lg="4" id={index + 2} className="pb-card d-flex">
                                                <ResourceCard item={items[index + 2]} colHeight={colHeight} />
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                )}
                            </ConditionalWrapper>
                        ))}
                    </MDBCarouselInner>
                </MDBCarousel>
            </div>
        );

        // Tablet version (visible only on medium screens)
        const tabletCarousel = (
            <div className="d-none d-md-block d-lg-none">
                <MDBCarousel
                    activeItem={1}
                    length={Math.ceil(items.length / 2)}
                    slide={true}
                    showControls={false}
                    showIndicators={true}
                    multiItem
                    key={`carousel-tablet-${categoryId}-${carouselKey}`}
                >
                    <MDBCarouselInner>
                        {items.map((_, index) => (
                            <ConditionalWrapper
                                key={index}
                                condition={index % 2 === 0}
                                wrapper={children => (<MDBCarouselItem itemId={index === 0 ? 1 : index % 2 === 0 ? index / 2 + 1 : 0}>{children}</MDBCarouselItem>)}
                            >
                                {index % 2 === 0 && (
                                    <MDBRow className="mx-0">
                                        <MDBCol md="6" id={index} className="pb-card d-flex">
                                            <ResourceCard item={items[index]} colHeight={colHeight} />
                                        </MDBCol>

                                        {index + 1 < items.length && (
                                            <MDBCol md="6" id={index + 1} className="pb-card d-flex">
                                                <ResourceCard item={items[index + 1]} colHeight={colHeight} />
                                            </MDBCol>
                                        )}
                                    </MDBRow>
                                )}
                            </ConditionalWrapper>
                        ))}
                    </MDBCarouselInner>
                </MDBCarousel>
            </div>
        );

        // Mobile version (visible only on small and extra small screens)
        const mobileCarousel = (
            <div className="d-block d-md-none">
                <MDBCarousel
                    activeItem={1}
                    length={items.length}
                    slide={true}
                    showControls={false}
                    showIndicators={true}
                    multiItem={false}
                    key={`carousel-mobile-${categoryId}-${carouselKey}`}
                >
                    <MDBCarouselInner>
                        {items.map((_, index) => (
                            <MDBCarouselItem key={index} itemId={index + 1}>
                                <MDBRow className="mx-0">
                                    <MDBCol xs="12" id={index} className="pb-card d-flex">
                                        <ResourceCard item={items[index]} colHeight={colHeight} />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCarouselItem>
                        ))}
                    </MDBCarouselInner>
                </MDBCarousel>
            </div>
        );

        return (
            <>
                {desktopCarousel}
                {tabletCarousel}
                {mobileCarousel}
            </>
        );
    };

    // State to track when to completely remount carousels
    const [carouselKey, setCarouselKey] = useState(0);

    // Function to handle tab change with preventDefault to avoid navigation
    const toggleCategory = (categoryId, e) => {
        if (e) {
            e.preventDefault(); // Prevent default link behavior
        }
        if (activeCategory !== categoryId) {
            setActiveCategory(categoryId);

            // Force remount of all carousels by changing the key
            setCarouselKey(prevKey => prevKey + 1);

            // Additional DOM manipulation attempt after a short delay
            setTimeout(() => {
                try {
                    // Try to find and click the first indicator dot in the newly active tab
                    const activeTabPane = document.querySelector(`.tab-pane[id="${categoryId}"]`);
                    if (activeTabPane) {
                        const firstIndicator = activeTabPane.querySelector('.carousel-indicators li:first-child');
                        if (firstIndicator) {
                            firstIndicator.click();
                        }
                    }
                } catch (error) {
                    console.error('Failed to reset carousel via DOM:', error);
                }
            }, 100); // Slightly longer timeout to ensure DOM is ready
        }
    };

    // Only show tabs if there are multiple categories
    const showTabs = availableCategories.length > 1;

    // Find the active category data
    const activeCategoryData = availableCategories.find(cat => cat.id === activeCategory) || availableCategories[0];

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol>
                    <p className="font-alt font-w-700 text-left letter-spacing-1 mt-4 mt-xl-0 mb-3 title-xs-medium title-large">
                        {props.data.title}
                    </p>
                    {props.data.description &&
                        <p className="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium text-left pb-4">
                            {props.data.description}
                        </p>
                    }
                </MDBCol>
            </MDBRow>

            {/* Only show category tabs if there's more than one category */}
            {showTabs && (
                <MDBNav className="nav-tabs my-4">
                    {availableCategories.map((category) => (
                        <MDBNavItem key={category.id}>
                            {/* Use a regular button styled like a tab with count badge */}
                            <button
                                type="button"
                                onClick={(e) => toggleCategory(category.id, e)}
                                className={`nav-link ${activeCategory === category.id ? 'active' : ''}`}
                            >
                                {category.name} <span className={`font-w-400 ${activeCategory === category.id ? 'text-white' : 'text-dark'}`}>({category.items.length})</span>
                            </button>
                        </MDBNavItem>
                    ))}
                </MDBNav>
            )}


            {/* View All Link - dynamic based on active category */}
            {/* {activeCategoryData.viewAllLink && (
        <div className="text-center my-5">
          <CustomLink link={activeCategoryData.viewAllLink} className="view-all-link">
            {activeCategoryData.viewAllText || `View all ${activeCategoryData.name.toLowerCase()} →`}
          </CustomLink>
        </div>
      )} */}

            {/* Tab Content */}
            <MDBTabContent activeItem={activeCategory} key={`tab-content-${carouselKey}`}>
                {availableCategories.map((category) => (
                    <MDBTabPane key={`${category.id}-${carouselKey}`} tabId={category.id}>
                        {renderCategoryCarousel(category.items, category.id)}
                    </MDBTabPane>
                ))}
            </MDBTabContent>
        </MDBContainer>
    );
};

export default ResourceCarousel