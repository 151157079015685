import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import {targetingEnabled} from '../utils/helpersCookies.js'
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import CustomLink from '../components/CustomLink.js'
import ColumnAlternating from '../components/columnAlternating'
import ColumnAlternatingButton from '../components/columnAlternatingButton'
import ColumnAlternatingWistia from '../components/columnAlternatingWistia'
import RecommendedContent from "../components/recommendedContent"
import Cta from '../components/cta'
import LogoCarousel from '../components/logoCarousel'
import ResourceCarousel from '../components/resourceCarousel.js'

const LiquidCooling = ({ data }) => {
  const post = data.markdownRemark
  const famplats = data.allMarkdownRemark.edges
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white mb-n5">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <section className="bg-white mt-n5">
          <FadeInWhenVisible>
            <LogoCarousel data={post.frontmatter.partnerlogos} />
          </FadeInWhenVisible>
        </section>        

        <RecommendedContent />

        <section className={`${targetingEnabled() ? 'bg-white' : 'bg-white-grey-gradient'}`}>
          <FadeInWhenVisible>
            <MDBContainer>
              {post.frontmatter.podcast.link.indexOf("wistia") > 0 ? 
                <ColumnAlternatingWistia
                  textCol="5"
                  imageCol="7"
                  placement={post.frontmatter.podcast.placement}
                  headingLevel={2}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  height={post.frontmatter.podcast.height}
                  ctalink={post.frontmatter.podcast.ctalink}
                  ctatext={post.frontmatter.podcast.ctatext}
                  icon={post.frontmatter.podcast.icon}
                />
              :
                <ColumnAlternatingButton
                  textCol="5"
                  imageCol="7"
                  headingLevel={2}
                  title={post.frontmatter.podcast.title}
                  subtitle={post.frontmatter.podcast.subtitle}
                  description={post.frontmatter.podcast.description}
                  image={post.frontmatter.podcast.image.childImageSharp.gatsbyImageData}
                  alt={post.frontmatter.podcast.alttext}
                  imageClass="rounded drop-shadow"
                  placement={post.frontmatter.podcast.placement}
                  titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                  link={post.frontmatter.podcast.link}
                  linktext={post.frontmatter.podcast.linktext}
                  ctalink={post.frontmatter.podcast.ctalink}
                  ctatext={post.frontmatter.podcast.ctatext}
                  icon={post.frontmatter.podcast.icon}
                />
              }
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => (
                    <ColumnAlternating
                      key={index}
                      textCol="7"
                      imageCol="5"
                      headingLevel={2}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  ))}
                </div>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ) : (
          ''
        )}

        {post.frontmatter.requirement ? (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <div className="about-overview">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">{post.frontmatter.requirement.title}</h2>
                  <p className="pb-sm-2 pb-md-5 font-w-400 text-medium">{post.frontmatter.requirement.description}</p>

                  {post.frontmatter.requirement.section && (
                    <>
                      {post.frontmatter.requirement.section.map( (sections, index) => (
                        <ColumnAlternating
                          key={index}
                          textCol="7"
                          imageCol="5"
                          title={sections.title}
                          subtitle={sections.subtitle}
                          subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                          description={sections.description}
                          image={sections.image.childImageSharp.gatsbyImageData}
                          alt={sections.alttext}
                          placement={sections.placement}
                          titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                          link={sections.btnlink}
                          colour={sections.btncolour}
                        />
                      ))}
                    </>
                  )}
                </div>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ) : (
          ''
        )}

        <Cta
          ctatitle={post.frontmatter.simplify.title}
          ctadescription={post.frontmatter.simplify.description}
          ctalink={post.frontmatter.simplify.link}
          ctalinktext={post.frontmatter.simplify.linktext}
        />

        {famplats &&
          <>
          {post.frontmatter.platform.section.map((section, index) => {
            let gen = index === 0 ? 4 : 3
            return (
            <section className={index === 0 ? "bg-white" : "bg-white-grey-gradient"} key={index}>
              <FadeInWhenVisible>
                <MDBContainer>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">
                    {section.title}
                  </h2>
                  {section.subtitle && 
                    <div className="mb-5 font-w-400 text-medium">
                      {section.subtitle}
                    </div>
                  }

                  {famplats.sort((a, b) => a.node.frontmatter.sequence == b.node.frontmatter.sequence ? 0 : 
                    a.node.frontmatter.sequence < b.node.frontmatter.sequence ? -1 : 1).map(({ node }, subindex) => (
                      <MDBRow className="flex-row" key={subindex}>
                        {node.frontmatter.platformsbyfamily && (
                          <>
                            {node.frontmatter.platformsbyfamily.sort((a, b) => a.frontmatter.sort == b.frontmatter.sort ? 0 : 
                              a.frontmatter.sort < b.frontmatter.sort ? -1 : 1).map((platform, subindex) => (
                                <Fragment key={subindex}>
                                  {platform.frontmatter.section.filter((platform) => (platform.eol !== true && platform.generation === gen)).map((details, subindex) => (
                                    <MDBCol md="4" className="py-3 d-flex" key={subindex}>
                                      <MDBCard className="flex-fill">
                                        <div style={{ minHeight: '200px', maxHeight: '220px' }}>
                                          <GatsbyImage 
                                            image={details.image.childImageSharp.gatsbyImageData}
                                            className="m-3"
                                            alt={details.alttext}
                                          />
                                        </div>
                                        <MDBCardBody>
                                          <h3 className="card-title text-center title-extra-small font-w-700">
                                            {details.title}
                                          </h3>
                                          <div className="text-center">
                                            {details.feature ? (
                                              <CustomLink
                                                link={platform.fields.slug ? platform.fields.slug : details.link}
                                                className="effect text-medium font-w-400"
                                              >                                                    
                                                {platform.fields.slug ? 'View platform page' : 'View datasheet'}
                                              </CustomLink>
                                            ) : (
                                            details.document && details.document.publicURL ? (
                                              <a 
                                                href={details.document.publicURL}
                                                target="_blank"
                                                rel="noopener"
                                                className="effect text-medium font-w-400 effect"
                                              >
                                                View datasheet
                                              </a>
                                            ) : (
                                              ''  // JDO: Empty string if no datasheet exists
                                            )
                                            )}
                                          </div>
                                        </MDBCardBody>
                                      </MDBCard>
                                    </MDBCol>
                                  ))}
                                </Fragment>
                              ))}
                          </>
                        )}
                      </MDBRow>
                    ))}
                </MDBContainer>
              </FadeInWhenVisible>
            </section>
          )})}
          </>
        }

      {post.frontmatter.carousel && (
        <section className="bg-white">
          <FadeInWhenVisible>
            <ResourceCarousel data={post.frontmatter.carousel} />
          </FadeInWhenVisible>
        </section>
      )}        

        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      </main>
    </Layout>
  )
}
export default LiquidCooling

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "liquid-cooling" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        openingpara
        partnerlogos {
          title
          description
          section {
            image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 286, height: 100, quality: 100)
 
              }
            }           
            alttext
            link
          }
        }        
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
        requirement {
          title
          description
          section {
            title          
            subtitle
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            placement
            alttext
            btnlink
            btncolour
        }
      }
        podcast {
          title
          subtitle
          description
          placement
          linktext
          link
          ctatext
          ctalink
          icon
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        simplify {
          title
          description
          linktext
          link
        }
        carousel {
          title
          description
          categoryList {
            id
            displayName
            viewAllLink
            viewAllText
            items {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 90)
                }
              }
              alttext
              link
              linktext
              title
              description
              document {
                publicURL
              }              
            }
          }
        }          
        platform {
          section {
            title
            subtitle
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
    allMarkdownRemark(
      filter: {frontmatter: {familyname: {eq: "Liquid and Immersion Cooling-Based Servers"}}},
      sort: {fields: frontmatter___platformsbyfamily___frontmatter___sort, order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            familyname
            sequence
            platformsbyfamily {
              frontmatter {
                template
                sort
                title
                subtitle
                foreimage {
                  childImageSharp {
                    gatsbyImageData(quality: 90) 
                  }
                }
                section {
                  title
                  eol
                  link
                  linktext
                  document {
                    publicURL
                  }
                  generation
                  alttext
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 90) 
                    }
                  }
                  feature {
                    title
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  }
`