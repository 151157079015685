import React from 'react'
import { MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import CustomLink from './CustomLink'

const ResourceCard = ({ item, colHeight }) => (
  <MDBCard className="mb-4 flex-fill">
    <CustomLink link={item.link || item.document?.publicURL || "#"}>
      <GatsbyImage
        image={item.image.childImageSharp.gatsbyImageData}
        alt={item.alttext}
        className="rounded-top drop-shadow"
      />
    </CustomLink>
    <MDBCardBody>
      <div style={colHeight}>
        <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">
          {item.title}
        </h3>
        <MDBCardText className="font-w-400 text-medium mt-2 mb-2">
          {item.description}
        </MDBCardText>
      </div>
      <hr />
      <div className="card-footer-resource">
      <CustomLink link={item.link || item.document?.publicURL || "#"} className="effect">
        {item.linktext}
      </CustomLink>
      </div>
    </MDBCardBody>
  </MDBCard>
);

export default ResourceCard